import { template as template_4c0e84b195d542f2b3639bf7873004cd } from "@ember/template-compiler";
const FKText = template_4c0e84b195d542f2b3639bf7873004cd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_f2f5c6a265d14ca297ad572af4f43134 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f2f5c6a265d14ca297ad572af4f43134(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_998048a9db6e4a7d9e1991c05f3c7b32 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_998048a9db6e4a7d9e1991c05f3c7b32(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;

import { template as template_a370538de01a43f09f96bd2770c5be4d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a370538de01a43f09f96bd2770c5be4d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
